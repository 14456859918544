<template>
  <div class="avv_dialog-wrapper" :class="wrapperClass">
    <div class="avv_dialog" v-bind="$attrs">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: ['wrapperClass'],
  setup: (props) => {
    return {
      wrapperClass: props.wrapperClass
    }
  }
})
</script>
